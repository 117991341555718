var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',{staticClass:"content-fix org"},[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('h2',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.$route.meta.title[_vm.$i18n.locale])+" ")]),_c('a-row',{staticClass:"post-search",staticStyle:{"justify-content":"flex-end"},attrs:{"type":"flex"}},[_c('a-button',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'senate-posts' })}}},[_vm._v(" "+_vm._s(_vm.btnTitle[_vm.$i18n.locale])+" ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'senate-inquiries-create' })}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(_vm._s(_vm.$t("Add"))+" ")],1)],1)],1),_c('a-row',{staticClass:"table__filtrs",attrs:{"type":"flex"}},[_c('a-select',{staticClass:"table__parpage",staticStyle:{"margin-right":"10px"},attrs:{"option-filter-prop":"children","placeholder":"Все"},on:{"change":_vm.tableperPage},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},_vm._l((_vm.LimitList),function(limit,index){return _c('a-select-option',{key:index,attrs:{"value":limit.value}},[_vm._v(" "+_vm._s(limit.title)+" ")])}),1),_c('a-button',{attrs:{"disabled":_vm.cardsIsEmpty,"type":"primary"},on:{"click":function($event){return _vm.removeItem(_vm.selectedPosts)}}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"delete"}}),_vm._v(" "+_vm._s(_vm.$t("Delete"))+" ")],1)],1),_c('a-table',{key:_vm.$route.fullPath,attrs:{"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableProps.results,"pagination":false},scopedSlots:_vm._u([{key:"news_title",fn:function(item){return _c('div',{staticClass:"td-post-item",on:{"click":function($event){return _vm.$router.push({
          name: 'senate-inquiries-detail',
          params: { id: item.id },
        })}}},[_c('b',{staticStyle:{"cursor":"pointer"}},[_vm._v(_vm._s(item.person && item.person.full_name))])])}},{key:"id",fn:function(item){return [_c('a-checkbox',{attrs:{"checked":_vm.selectedPosts.includes(+item.id),"value":+item.id},on:{"change":function($event){return _vm.toggleItemId(item.id)}}})]}},{key:"status",fn:function(item){return [_c('a-tag',{attrs:{"color":item.status ? 'geekblue' : 'volcano'}},[_vm._v(" "+_vm._s(item.status ? _vm.$t("Published") : _vm.$t("NotPublished"))+" ")])]}},{key:"operation",fn:function(item){return [_c('a-button',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'senate-inquiries-detail',
            params: { id: item.id },
          })}}},[_vm._v(" "+_vm._s(_vm.$t("Edit"))+" "),_c('a-icon',{staticClass:"action-btns",attrs:{"type":"edit"}})],1),(item.type == 1)?_c('a-button',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'article',
            params: { senator: item.id },
          })}}},[_vm._v(" "+_vm._s(_vm.$t('Articles'))+" ")]):_vm._e()]}}])}),_c('a-row',{staticClass:"mt-1",staticStyle:{"padding-bottom":"15px"}},[_c('a-pagination',{attrs:{"current":_vm.page,"default-page-size":_vm.perPage,"total":+_vm.tableProps.count || 0},on:{"change":_vm.toPage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }